// extracted by mini-css-extract-plugin
export var navbarOpen = "ChatCallBlock-module--navbar-open--1hDnA";
export var isModal = "ChatCallBlock-module--isModal--3BlcE";
export var isLoaded = "ChatCallBlock-module--is-loaded--2OQEI";
export var Plx = "ChatCallBlock-module--Plx--3ZfeV";
export var customTooltip = "ChatCallBlock-module--customTooltip--3FG6P";
export var calculatorContainer__wrapper = "ChatCallBlock-module--calculatorContainer__wrapper--3kiCL";
export var posRelative = "ChatCallBlock-module--posRelative--2e-fw";
export var calculatorSection = "ChatCallBlock-module--calculator-section--DEqeO";
export var refiAdviceSection = "ChatCallBlock-module--refi-advice-section--1KkHN";
export var faqSection = "ChatCallBlock-module--faq-section--3_sYv";
export var glossarySection = "ChatCallBlock-module--glossary-section--3ZUvL";
export var homeBuyAdvice = "ChatCallBlock-module--home-buy-advice--3NtPT";
export var ourBeliefs = "ChatCallBlock-module--our-beliefs--2KWbK";
export var meetTeam = "ChatCallBlock-module--meet-team--1d6N0";
export var aboutcake = "ChatCallBlock-module--aboutcake--3rqW0";
export var ourMission = "ChatCallBlock-module--our-mission--2zp-r";
export var ourStory = "ChatCallBlock-module--our-story--2Lu-b";
export var mainWrapper = "ChatCallBlock-module--main-wrapper--2F4iC";
export var btn = "ChatCallBlock-module--btn--2CCpH";
export var iconWrapper = "ChatCallBlock-module--icon-wrapper--1iQpd";
export var small = "ChatCallBlock-module--small--1Uj38";
export var green = "ChatCallBlock-module--green--uDITv";
export var greenFill = "ChatCallBlock-module--green--fill--2r23Y";
export var greenBorder = "ChatCallBlock-module--green--border--oH7do";
export var dark = "ChatCallBlock-module--dark--3At0H";
export var darkLg = "ChatCallBlock-module--dark--lg--3jKu1";
export var p1 = "ChatCallBlock-module--p1--23ejs";
export var p2 = "ChatCallBlock-module--p2--3oHsV";
export var centerAlign = "ChatCallBlock-module--center-align--S9GG-";
export var leftAlign = "ChatCallBlock-module--left-align--2NrU_";
export var section = "ChatCallBlock-module--section--143Co";
export var contentsWrap = "ChatCallBlock-module--contents-wrap--11MUF";
export var lDesktop = "ChatCallBlock-module--l-desktop--2MVIU";
export var lMobile = "ChatCallBlock-module--l-mobile--2CsUO";
export var sectionHead = "ChatCallBlock-module--section-head--jAF44";
export var hidMob = "ChatCallBlock-module--hid-mob--30IOq";
export var hidDsktp = "ChatCallBlock-module--hid-dsktp--1oYTF";
export var CakeFormWrap = "ChatCallBlock-module--CakeFormWrap--2uB1Y";
export var CakeFieldWrap = "ChatCallBlock-module--CakeFieldWrap--1n5ZC";
export var inputDollarWrap = "ChatCallBlock-module--input--dollar-wrap--3QS4_";
export var customRange = "ChatCallBlock-module--custom-range--1GS1O";
export var customRange__track = "ChatCallBlock-module--custom-range__track--1xPPF";
export var customRange__thumb = "ChatCallBlock-module--custom-range__thumb--Bba2F";
export var thumbValue = "ChatCallBlock-module--thumb-value--2KW5t";
export var container = "ChatCallBlock-module--container--1o6bu";
export var containerSm = "ChatCallBlock-module--container--sm--1e6ww";
export var cake404 = "ChatCallBlock-module--cake404--3Gc-d";
export var cake404__wrapper = "ChatCallBlock-module--cake404__wrapper--3XTB6";
export var cake404__figure = "ChatCallBlock-module--cake404__figure--2jLcp";
export var cake404__contents = "ChatCallBlock-module--cake404__contents--16noC";
export var contents__top = "ChatCallBlock-module--contents__top--1KBcJ";
export var contents__bottom = "ChatCallBlock-module--contents__bottom--37nEK";
export var footIcon = "ChatCallBlock-module--footIcon--aVNu0";
export var genericSection = "ChatCallBlock-module--generic-section--22VGB";
export var titleSection = "ChatCallBlock-module--title-section--DkMVH";
export var PersonalizeModal = "ChatCallBlock-module--PersonalizeModal--1Rac1";
export var onetrustConsentSdk = "ChatCallBlock-module--onetrust-consent-sdk--3Mtct";
export var onetrustBannerSdk = "ChatCallBlock-module--onetrust-banner-sdk--1qHxN";
export var onetrustButtonGroup = "ChatCallBlock-module--onetrust-button-group--X2M3B";
export var onetrustPcDarkFilter = "ChatCallBlock-module--onetrust-pc-dark-filter--dqE5Q";
export var onetrustPcSdk = "ChatCallBlock-module--onetrust-pc-sdk--3BFCs";
export var otSdkBtnFloating = "ChatCallBlock-module--ot-sdk-btn-floating--287Dx";
export var calculatorContainer = "ChatCallBlock-module--calculatorContainer--vJ7Db";
export var dsktRes = "ChatCallBlock-module--dskt-res--zORh-";
export var disBtn = "ChatCallBlock-module--dis-btn--26A-x";
export var otFloatingButton__front = "ChatCallBlock-module--ot-floating-button__front--24an3";
export var otFloatingButton__back = "ChatCallBlock-module--ot-floating-button__back--1i3A1";
export var Article__wrapper = "ChatCallBlock-module--Article__wrapper--2e28r";
export var ArticleBanner = "ChatCallBlock-module--Article-banner--22Dp3";
export var ArticleBanner__top = "ChatCallBlock-module--Article-banner__top--3pd5w";
export var ArticleBanner__title = "ChatCallBlock-module--Article-banner__title--673_h";
export var ArticleBanner__readTime = "ChatCallBlock-module--Article-banner__read-time--3HOnG";
export var ArticleBanner__image = "ChatCallBlock-module--Article-banner__image--2X6nr";
export var ArticleBanner__bottom = "ChatCallBlock-module--Article-banner__bottom--1sdQv";
export var ArticleBanner__subtitle = "ChatCallBlock-module--Article-banner__subtitle--3itR5";
export var ArticleContents = "ChatCallBlock-module--Article-contents--wUWGH";
export var Article__sources = "ChatCallBlock-module--Article__sources--3ewlT";
export var perspectiveWrap = "ChatCallBlock-module--perspectiveWrap--3vUpT";
export var ChatCallBlock = "ChatCallBlock-module--ChatCallBlock--2vXQg";