// extracted by mini-css-extract-plugin
export var navbarOpen = "GlossaryBlock-module--navbar-open--2ZxE4";
export var isModal = "GlossaryBlock-module--isModal--2i2OH";
export var isLoaded = "GlossaryBlock-module--is-loaded--2r7W3";
export var Plx = "GlossaryBlock-module--Plx--2Jtk9";
export var customTooltip = "GlossaryBlock-module--customTooltip--3dv53";
export var calculatorContainer__wrapper = "GlossaryBlock-module--calculatorContainer__wrapper--2mAcF";
export var posRelative = "GlossaryBlock-module--posRelative--vgiSU";
export var calculatorSection = "GlossaryBlock-module--calculator-section--2WMC8";
export var refiAdviceSection = "GlossaryBlock-module--refi-advice-section--163mB";
export var faqSection = "GlossaryBlock-module--faq-section--1yZes";
export var glossarySection = "GlossaryBlock-module--glossary-section--2rUMc";
export var homeBuyAdvice = "GlossaryBlock-module--home-buy-advice--1aRfc";
export var ourBeliefs = "GlossaryBlock-module--our-beliefs--3SF-J";
export var meetTeam = "GlossaryBlock-module--meet-team--3F5kS";
export var aboutcake = "GlossaryBlock-module--aboutcake--SgT7p";
export var ourMission = "GlossaryBlock-module--our-mission--3sHYt";
export var ourStory = "GlossaryBlock-module--our-story--3V8C1";
export var mainWrapper = "GlossaryBlock-module--main-wrapper--Hbe48";
export var btn = "GlossaryBlock-module--btn--18V31";
export var iconWrapper = "GlossaryBlock-module--icon-wrapper--IZei2";
export var small = "GlossaryBlock-module--small--2UPn1";
export var green = "GlossaryBlock-module--green--39NyQ";
export var greenFill = "GlossaryBlock-module--green--fill--194yd";
export var greenBorder = "GlossaryBlock-module--green--border--3NQ9t";
export var dark = "GlossaryBlock-module--dark--3nZsm";
export var darkLg = "GlossaryBlock-module--dark--lg--3y9dt";
export var p1 = "GlossaryBlock-module--p1--3EI4a";
export var p2 = "GlossaryBlock-module--p2--l1Atg";
export var centerAlign = "GlossaryBlock-module--center-align--c1HYy";
export var leftAlign = "GlossaryBlock-module--left-align--37yVm";
export var section = "GlossaryBlock-module--section--7DjoU";
export var contentsWrap = "GlossaryBlock-module--contents-wrap--1ZqNs";
export var lDesktop = "GlossaryBlock-module--l-desktop--2FQBi";
export var lMobile = "GlossaryBlock-module--l-mobile--24sw5";
export var sectionHead = "GlossaryBlock-module--section-head--34aUA";
export var hidMob = "GlossaryBlock-module--hid-mob--1uppj";
export var hidDsktp = "GlossaryBlock-module--hid-dsktp--2DP9N";
export var CakeFormWrap = "GlossaryBlock-module--CakeFormWrap--19Sxc";
export var CakeFieldWrap = "GlossaryBlock-module--CakeFieldWrap--1opaK";
export var inputDollarWrap = "GlossaryBlock-module--input--dollar-wrap--3A1iD";
export var customRange = "GlossaryBlock-module--custom-range--2bWTt";
export var customRange__track = "GlossaryBlock-module--custom-range__track--14R5w";
export var customRange__thumb = "GlossaryBlock-module--custom-range__thumb--3HJBs";
export var thumbValue = "GlossaryBlock-module--thumb-value--1anPt";
export var container = "GlossaryBlock-module--container--2x4kR";
export var containerSm = "GlossaryBlock-module--container--sm--jrzMv";
export var cake404 = "GlossaryBlock-module--cake404--3nvXO";
export var cake404__wrapper = "GlossaryBlock-module--cake404__wrapper--UMskJ";
export var cake404__figure = "GlossaryBlock-module--cake404__figure--OIsLG";
export var cake404__contents = "GlossaryBlock-module--cake404__contents--2VTSb";
export var contents__top = "GlossaryBlock-module--contents__top--15a6a";
export var contents__bottom = "GlossaryBlock-module--contents__bottom--2D1xF";
export var footIcon = "GlossaryBlock-module--footIcon--orlG1";
export var genericSection = "GlossaryBlock-module--generic-section--2C4Kr";
export var titleSection = "GlossaryBlock-module--title-section--eSnnD";
export var PersonalizeModal = "GlossaryBlock-module--PersonalizeModal--1pve7";
export var onetrustConsentSdk = "GlossaryBlock-module--onetrust-consent-sdk--5CISJ";
export var onetrustBannerSdk = "GlossaryBlock-module--onetrust-banner-sdk--tL5wC";
export var onetrustButtonGroup = "GlossaryBlock-module--onetrust-button-group--1pd4E";
export var onetrustPcDarkFilter = "GlossaryBlock-module--onetrust-pc-dark-filter--kJ43-";
export var onetrustPcSdk = "GlossaryBlock-module--onetrust-pc-sdk--3CODr";
export var otSdkBtnFloating = "GlossaryBlock-module--ot-sdk-btn-floating--3U8aM";
export var calculatorContainer = "GlossaryBlock-module--calculatorContainer--3r0aA";
export var dsktRes = "GlossaryBlock-module--dskt-res--XRvY-";
export var disBtn = "GlossaryBlock-module--dis-btn--1bgGw";
export var otFloatingButton__front = "GlossaryBlock-module--ot-floating-button__front--EISt3";
export var otFloatingButton__back = "GlossaryBlock-module--ot-floating-button__back--2Se7d";
export var Article__wrapper = "GlossaryBlock-module--Article__wrapper--2lIDr";
export var ArticleBanner = "GlossaryBlock-module--Article-banner--2Tss4";
export var ArticleBanner__top = "GlossaryBlock-module--Article-banner__top--3JubM";
export var ArticleBanner__title = "GlossaryBlock-module--Article-banner__title--3ShJ1";
export var ArticleBanner__readTime = "GlossaryBlock-module--Article-banner__read-time--2lqUT";
export var ArticleBanner__image = "GlossaryBlock-module--Article-banner__image--3P22G";
export var ArticleBanner__bottom = "GlossaryBlock-module--Article-banner__bottom--17d8x";
export var ArticleBanner__subtitle = "GlossaryBlock-module--Article-banner__subtitle--LVZWe";
export var ArticleContents = "GlossaryBlock-module--Article-contents--h_gC3";
export var Article__sources = "GlossaryBlock-module--Article__sources--1M7bf";
export var perspectiveWrap = "GlossaryBlock-module--perspectiveWrap--3Xllv";
export var GlossaryBlock = "GlossaryBlock-module--GlossaryBlock--3nLhs";